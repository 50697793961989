import { useState, useEffect, useRef } from 'react';
import axios from 'axios'

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import 'bootstrap/dist/css/bootstrap.min.css';
import './EstiloMenu.css'
import { Facebook, Whatsapp, TelephoneFill, Instagram, ArrowLeft } from 'react-bootstrap-icons';

import blog1 from './img/blog/b1.jpg'
import blog2 from './img/blog/b2.jpg'
import blog3 from './img/blog/b3.jpg'

//import mipsa from './cliente-mipsa.png'
//import { useState, useEffect, useRef } from 'react';
function Knows() {
  const [ detail, setDetail ] = useState( false )
  const [ blog, setBlog ] = useState( false )
    return (
      <>
        { !detail &&
          <div className="banner-bg-services-b" align="center">
            <Container>
              <br/>
              <br/>
              <br/>
              <br/>
              <div align="left" className='nav-back' onClick={ () => window.location.href = '/' }> <ArrowLeft />&nbsp; <span> Atrás </span> </div>
            </Container>
            <div className='section'>
              <Container>
                <Row>
                  <Col>
                    <div className='banner-title-page'> ¿Sabías que? </div><br/><br/><br/><br/>

                    <div className='banner-desc'> Conoce noticias y datos interesantes que suceden en el país para estar al día con todo el ámbito de la contaduría. </div>
                  </Col>
                </Row>
              </Container>
              <br/><br/><br/><br/>

              <Container>
                <Row className='align-items-center' onClick={ () => { setDetail( true ); setBlog(1); }} style={ { cursor: 'pointer' } }>
                  <Col md={ 6 }>
                    <div className='generic-image' style={{ backgroundImage: 'url(' + blog1 + ')' }}></div>
                  </Col>
                  <Col md={ 6 } align="left">
                    <div className='banner-title-small-new'> ¿COMO AFECTA LA RECESIÓN ECONÓMICA MIS INVERSIONES? </div> <br/>
                    <div className='banner-desc-small'> Los principales indicadores para que esto ocurra son los incrementos de precios e inflación; esto afecta para consumidores y empresas. </div><br/>
                    <div className='banner-desc-small' align="right"> <b>Leer más...</b> </div>
                  </Col>
                </Row>
                <br/><br/>
                <Row className='align-items-center' onClick={ () => { setDetail( true ); setBlog(2); }} style={ { cursor: 'pointer' } }>
                  <Col md={ 6 }>
                    <div className='generic-image' style={{ backgroundImage: 'url(' + blog2 + ')' }}></div> 
                  </Col>
                  <Col md={ 6 } align="left">
                  <div className='banner-title-small-new'> Prepárate para tu Auditoria Fiscal del SAT </div> <br/>
                    <div className='banner-desc-small'> El SAT es la mayor autoridad en este mundo de los números y a quien le impartimos cuentas, por eso es importante mantener en orden todo lo necesario, aunque, aun así, puede que el SAT lo audite. </div><br/>
                    <div className='banner-desc-small' align="right"> <b>Leer más...</b> </div>
                  </Col>
                </Row>
                <br/><br/>
                <Row className='align-items-center' onClick={ () => { setDetail( true ); setBlog(3); }} style={ { cursor: 'pointer' } }>
                  <Col md={ 6 }>
                    <div className='generic-image' style={{ backgroundImage: 'url(' + blog3 + ')' }}></div>
                  </Col>
                  <Col md={ 6 } align="left">
                  <div className='banner-title-small-new'> AUMENTA TUS VENTAS CON ESTOS TIPS PARA TU EMPRESA </div> <br/>
                    <div className='banner-desc-small'> Ya sea que es tu pequeña empresa o eres emprendedor, estos tips pueden ayudarte a atraer clientes y lograr más ventas. </div><br/>
                    <div className='banner-desc-small' align="right"> <b>Leer más...</b> </div>
                  </Col>
                </Row>
              </Container>

            </div>

          </div>
        }

        { detail &&
          <div className="banner-bg-services-b" align="center">
            <Container>
              <br/>
              <br/>
              <br/>
              <br/>
              <div align="left" className='nav-back' onClick={ () => setDetail(false) }> <ArrowLeft />&nbsp; <span> Atrás </span> </div>
              <div className='section'>
                { blog === 1 &&
                  <Container>
                    <Row>
                      <Col>
                        <div className='banner-title-page' style={{ textAlign: 'left', textTransform: 'uppercase', color: '#000' }}> ¿COMO AFECTA LA RECESIÓN ECONÓMICA MIS INVERSIONES? </div>
                        <br/><br/>
                        <div className='banner-desc' style={{ textAlign: 'left', color: '#000' }}>
                          <div> Los principales indicadores para que esto ocurra son los incrementos de precios e inflación; esto afecta para consumidores y empresas </div>
                          <br/>
                          <div align="center"> <img src={ blog1 } style={{ width: '80%' }} /></div>
                          <br/>
                          <div> En 1974 se describió como una recesión a “dos trimestres consecutivos de crecimiento decreciente”, esto por el economista estadunidense Julius Shiskin, que aún varios países siguen usando este término.</div>
                          <br/>
                          <div>La recesión económica es un descenso significativo de la actividad económica que dura más de unos pocos meses, en esta se ve una reducción de producción de bienes y servicios y reduce la inversión, como consecuencia aumenta la falta de generación de empleos y la disponibilidad de dinero.</div><br/>
                          <div><b>Señal de una recesión</b></div><br/>
                          <div>Antes de una recesión usualmente hay un periodo de incremento económico, incrementos de precios, sobreproducción e inflación.</div>
                          <div>Hay que recordar que estos cambios afectan tanto para consumidores como para las empresas.</div><br/>
                          <div><b>Causas de una recesión</b></div><br/>
                          <div><b>Sobreoferta:</b></div><br/>
                          <div>Cuando hay más productos en el mercado de los que la gente puede comprar, las empresas reducen su producción. Esto significa que necesitan menos trabajadores, lo que lleva a despidos. Menos empleo significa menos dinero en los bolsillos de la gente, lo que a su vez reduce el consumo.</div><br/>
                          <div><b>Especulación:</b></div><br/>
                          <div>Los inversores compran un activo con la intención de obtener ganancias y esto hace que su precio suba de manera exagerada, llega un momento en que habrá más personas tratando de vender ese activo que personas dispuestas a comprarlo.</div><br/>
                          <div><b>Duda</b></div><br/>
                          <div>Se posponen decisiones de inversión de empresas y familias en momentos de sospecha</div><br/>
                          <div><b>¿Cómo me puedo prevenir para una recesión?</b></div><br/>
                          <div><b>Aumentar nuestra productividad</b></div><br/>
                          <div>Ahorrar e invertir bien nuestros recursos puede ayudarnos a proteger nuestro dinero durante una recesión</div><br/>
                          <div> En resumen, la recesión perjudica económicamente a muchas situaciones en un país, inversión, actividad económica, consumo y producción de bienes provocando que el PIB se reduzca ocasionando el aumento de la tasa de paro, cierre de empresas y pérdida de poder adquisitivo.</div><br/>
                        </div>
                        
                      </Col>
                    </Row>
                  </Container>
                }

                { blog === 2 &&
                  <Container>
                    <Row>
                      <Col>
                        <div className='banner-title-page' style={{ textAlign: 'left', textTransform: 'uppercase', color: '#000' }}> Prepárate para tu Auditoria Fiscal del SAT </div>
                        <br/><br/>
                        <div className='banner-desc' style={{ textAlign: 'left', color: '#000' }}>
                          <div> El SAT es la mayor autoridad en este mundo de los números y a quien le impartimos cuentas, por eso es importante mantener en orden todo lo necesario, aunque, aun así, puede que el SAT lo audite. </div>
                          <br/>
                          <div align="center"> <img src={ blog2 } style={{ width: '80%' }} /></div>
                          <br/>
                          <div><b>¿Qué es una Auditoria Fiscal?</b></div><br/>
                          <div> Es un examen profundo de la situación fiscal de tu empresa para personas físicas; se revisará tus registros contables y financieros, verificar el cumplimiento de tu declaración, pago de impuestos y obligaciones fiscales de acuerdo con sus reportes. </div>
                          <br/>
                          <div> Pueden realizar una exhaustiva revisión de los libros contables, declaraciones, documentos de transacciones o financieros de la </div>
                          <br/>
                          <div> En ciertos casos, es posible que se hagan visitas al domicilio o se soliciten documentos adicionales por parte del SAT. </div>
                          <br/>
                          <div> Hay que considerar que una Auditoria puede ayudarnos a prevenir situaciones perjudiciales a la persona en un futuro, ya que el SAT realiza estas auditorías a personas seleccionadas según un análisis con inconsistencia o riesgo. Aunque otras veces simplemente es aleatoriamente. </div>
                          <br/>
                          <div> Tengamos o no una próxima auditoria es indispensable siempre tener toda nuestra contabilidad clara, esto nos ayudará para poder prevenir cualquier auditoria o situación de riesgo financiero y fiscal para con la persona o empresa. </div>
                        </div>
                        
                      </Col>
                    </Row>
                  </Container>
                }

                { blog === 3 &&
                  <Container>
                    <Row>
                      <Col>
                        <div className='banner-title-page' style={{ textAlign: 'left', textTransform: 'uppercase', color: '#000' }}> AUMENTA TUS VENTAS CON ESTOS TIPS PARA TU EMPRESA </div>
                        <br/><br/>
                        <div className='banner-desc' style={{ textAlign: 'left', color: '#000' }}>
                          <div> Ya sea que es tu pequeña empresa o eres emprendedor, estos tips pueden ayudarte a atraer clientes y lograr más ventas. </div>
                          <br/>
                          <div align="center"> <img src={ blog3 } style={{ width: '80%' }} /></div>
                          <br/>
                          <div>
                            <ul>
                              <li> Establece metas claras: Define objetivos específicos y medibles para tu empresa a corto y largo plazo. Esto te ayudará a mantener el enfoque y a dirigir tus esfuerzos hacia el crecimiento y el éxito. </li>
                              <li> Implementa un sistema contable eficiente: Establece un sistema contable sólido para registrar y monitorear tus transacciones financieras. Esto te permitirá mantener un registro preciso de tus ingresos y gastos, facilitando la toma de decisiones financieras informadas. </li>
                              <li> Conoce a tu mercado y clientes: Realiza investigaciones de mercado para comprender las necesidades y preferencias de tus clientes. Esto te ayudará a adaptar tus productos o servicios para satisfacer mejor las demandas del mercado y atraer a más clientes. </li>
                              <li> Gestiona el flujo de efectivo: Mantén un control estricto sobre tu flujo de efectivo. Monitorea tus ingresos y gastos de cerca y asegúrate de tener suficiente efectivo disponible para cubrir tus obligaciones financieras, como pagos de proveedores y nómina de empleados. </li>
                              <li> Invierte en marketing y promoción: Destina recursos a estrategias de marketing y promoción para dar a conocer tu empresa y atraer nuevos clientes. Esto puede incluir publicidad en línea, participación en eventos locales, presencia en redes sociales y programas de fidelización de clientes. </li>
                              <li> Cuida a tus empleados: Reconoce el valor de tus empleados y bríndales un ambiente de trabajo positivo y motivador. Fomenta la comunicación abierta, ofrece oportunidades de desarrollo profesional y reconoce y recompensa el buen desempeño. </li>
                              <li> Mantén una cultura de mejora continua: Busca constantemente formas de mejorar y optimizar tus operaciones comerciales. Esté abierto a recibir retroalimentación de clientes y empleados, y esté dispuesto a realizar ajustes según sea necesario para mantener la competitividad y la eficiencia. </li>
                              <li> Administra el crecimiento de manera sostenible: Si experimentas un crecimiento rápido, asegúrate de administrarlo de manera sostenible. Mantén un equilibrio entre la demanda y la capacidad de tu empresa para evitar problemas como la falta de liquidez o la falta de recursos. </li>
                            </ul>

                          </div>
                        </div>
                        
                      </Col>
                    </Row>
                  </Container>
                }
                <br/>
              </div>
            </Container>
          </div>
        }
      </>
        
    );
}
export default Knows;


